import React, { useEffect } from "react"
import { Box, Flex } from "@chakra-ui/core"
import firebase from "gatsby-plugin-firebase"
import Layout from "../components/Layout"
import { H1, H2, B4 } from "../components/Typography"
import SEO from "../components/SEO"

const contactDetails = [
  {
    name: "Contact Number",
    value: "079 802 1142",
  },
  {
    name: "Whatsapp Number",
    value: "079 802 1142",
  },
  {
    name: "Facebook",
    value: "Laudium Today",
  },
  {
    name: "Twitter",
    value: "@laudiumtoday",
  },
  {
    name: "Instagram",
    value: "@laudiumtoday",
  },
  {
    name: "Email",
    value: "info@laudiumtoday.co.za",
  },
]

export default function AboutUs() {
  useEffect(() => {
    firebase.analytics().setCurrentScreen("contact_us")
  }, [])
  return (
    <Layout p={[4, 6]}>
      <SEO title="Contact Us" />
      <H1 textAlign="center">Contact Us</H1>
      <Flex align="center" justifyContent="center" w="100%">
        <Flex
          mt={8}
          p={8}
          direction="column"
          justify="center"
          align="center"
          shadow="md"
          overflow="hidden"
          borderRadius="24px"
          maxW="700px"
          w="100%"
        >
          {contactDetails.map(detail => (
            <Flex
              m={4}
              width="100%"
              justify="space-between"
              align="center"
              direction="column"
            >
              <H2 textAlign="center">{detail.name}:</H2>
              <B4>{detail.value}</B4>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Layout>
  )
}
